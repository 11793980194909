<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="column result my-1" v-if="!cuenta_prima_creada">
      <b-collapse animation="slide" :open.sync="isOpen">
        <div slot="trigger" slot-scope="props" role="button">
          <div class="columns card-header shadow-none mt-0 is-mobile">
            <div class="column flex flex-col justify-center">
              <p class="text-xl">Asignar garantía fondeo</p>
            </div>
            <div class="column is-4 flex items-center justify-end">
              <b-icon :icon="props.open ? 'chevron-down' : 'chevron-right'" />
            </div>
          </div>
        </div>
        <div class="has-border-radius mt-3 shadow-none z-0">
          <div class="columns">
            <div class="column is-6">
              <valid-select
                :rules="isOpen ? 'required' : ''"
                label="Garantia de portafolio"
                placeholder="Seleccionar..."
                v-model="tipos_garantia_portafolio"
                :data="getTiposGarantiaPortafolio"
                show="nombre"
                realValue="id"
                :expanded="true"
                :disabled="true"
              />
            </div>
            <div class="column is-6">
              <ValidInput
                :rules="isOpen ? 'required' : ''"
                label="Id de garantia"
                placeholder="Identificador de la garantia"
                v-model="identificador_garantia"
                type="text"
                :expanded="true"
              />
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <ValidInput
                :rules="isOpen ? 'required|min_value:0.00' : ''"
                label="Cobertura efectiva"
                placeholder="0.00"
                v-model="cobertura_efectiva"
                type="number"
                :expanded="true"
                addon
                addonIcon="percentage"
                v-if="
                  tipos_garantia_portafolio !== 16 &&
                    tipos_garantia_portafolio != null
                "
              />
            </div>
            <div class="column is-6">
              <ValidInput
                :rules="isOpen ? 'required|min_value:0.00' : ''"
                label="Costo Prima del fondeador"
                placeholder="0.00"
                v-model="prima_fondeador"
                type="number"
                :expanded="true"
                addon
                addonIcon="percentage"
                v-if="
                  tipos_garantia_portafolio !== 16 &&
                    tipos_garantia_portafolio != null
                "
              />
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <ValidInput
                :rules="isOpen ? 'required|min_value:0.00' : ''"
                label="Costo Prima al acreditado"
                placeholder="0.00"
                v-model="prima_acreditado"
                type="number"
                :expanded="true"
                addon
                addonIcon="percentage"
                v-if="
                  tipos_garantia_portafolio !== 16 &&
                    tipos_garantia_portafolio != null
                "
              />
            </div>
            <div class="column is-6">
              <valid-select
                :rules="isOpen ? 'required' : ''"
                label="Moneda"
                placeholder="Seleccionar..."
                v-model="moneda"
                :data="getMonedas"
                show="nombre"
                realValue="id"
                :expanded="true"
              />
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <valid-input
                label="Notas"
                :rules="isOpen ? 'required' : ''"
                placeholder="Notas..."
                v-model="notas"
                type="textarea"
                :expanded="true"
                v-if="
                  tipos_garantia_portafolio !== 16 &&
                    tipos_garantia_portafolio != null
                "
              />
            </div>
          </div>
          <div class="columns mt-4">
            <!-- <div class="column is-3">
              <b-button
                type="is-white"
                class="has-text-danger font-bold"
                expanded
                @click="$emit('cancelForm')"
              >
                Cancelar
              </b-button>
            </div> -->
            <div class="column">
              <b-button
                type="is-primary"
                expanded
                class="font-bold has-background-grey-darker has-text-white"
                @click="validate().then(result => asignar(result))"
              >
                Guardar asignación
              </b-button>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import ValidSelect from "@/components/form/ValidSelect";
import ValidInput from "@/components/form/ValidInput";

export default {
  name: "AsignarGarantiaFondeo",
  components: {
    ValidationObserver,
    ValidSelect,
    ValidInput
  },
  props: {
    cuentas: Array,
    disposicion: Object,
    icon: {
      type: String,
      default: "chevron-right"
    }
  },
  computed: {
    ...mapGetters("catalogos", ["getTiposGarantiaPortafolio","getMonedas"]),
    ...mapGetters("disposicion", ["generarCuentaPrima"])
  },
  data() {
    return {
      isOpen: false,
      tipos_garantia_portafolio: 4,
      identificador_garantia: null,
      moneda: null,
      cobertura_efectiva: 0,
      prima_fondeador: 0,
      prima_acreditado: 0,
      cuenta_prima_creada: false,
      notas: null,
      garantia: {
        valor: 0,
        tipos_garantia_id: null,
        moneda_id: null
      }
    };
  },
  async created() {
    await this.$store.dispatch('catalogos/getMonedas');
  },
  async mounted() {
    if (this.getTiposGarantiaPortafolio == null) {
      this.$store.dispatch("catalogos/getTiposGarantiaPortafolio");
    }
    this.existeCuentaPrima();
  },
  methods: {
    /*
        Selecciona el objeto como activo para mostrar su preview
        @tipo (String): tipo de objeto
        @item (Object): el objeto en si
        */
    // setActive(tipo, item) {
    //   this.$emit("active", {
    //     item,
    //   });
    // },

    asignar(result) {
      if (!result) {
        return;
      }
      let garantia = this.getTiposGarantiaPortafolio.find(
        garantia => garantia.id == this.tipos_garantia_portafolio
      );
      this.garantia.tipos_garantia_id = this.tipos_garantia_portafolio;
      this.garantia.moneda_id = this.moneda;
      this.garantia.personas_id = this.disposicion.acreditado_id;
      this.garantia.id_garantia = this.identificador_garantia;
      this.garantia.descripcion =
        "Garantia " +
        garantia.nombre +
        " asignada al crédito " +
        this.disposicion.folio_credito;
      let atributos = [];

      atributos.push({ id: 1, valor: this.cobertura_efectiva });
      atributos.push({ id: 2, valor: this.prima_fondeador });
      atributos.push({ id: 3, valor: this.prima_acreditado });
      atributos.push({ id: 6, valor: this.notas });
      this.garantia.atributos = atributos;
      this.$store
        .dispatch("disposicion/generarCuentaPrima", {
          disposicion_id: this.$route.params.idDisposicion,
          garantia: this.garantia
        })
        .then(() => {
          let data = this.generarCuentaPrima;
          if (data.cuenta_acreditado) {
            this.cuentas.push(data.cuenta_acreditado);
          }
          if (data.cuenta_fondeador) {
            this.cuentas.push(data.cuenta_fondeador);
          }
          if (this.cuentas.length > 0) {
            this.existeCuentaPrima();
          }
        });
    },
    existeCuentaPrima() {
      this.cuentas.forEach(cuenta => {
        if (
          cuenta.tipo_de_operacion_id == 122 ||
          cuenta.tipo_de_operacion_id == 123
        ) {
          this.cuenta_prima_creada = true;
          return;
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
.card-header {
  transition: all 0.3s ease-in-out;
  border-radius: $radius;
  cursor: pointer;
  background-color: #ececec;
  &:hover {
    background-color: #adadad;
  }
  &.active {
    background-color: rgba($primary, 0.16);
  }
}
</style>
