var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"w-full text-left",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('p',{staticClass:"text-3xl font-medium text-left has-text-grey-darker"},[_vm._v(" Ligar línea de crédito ")]),_c('div',{staticClass:"mb-3 has-text-grey-darker text-left"},[_vm._v(" Estas a punto de ligar una línea de crédito a "),(Array.isArray(_vm.disposicion_id))?_c('span',[_vm._v(_vm._s(_vm.arrendamiento ? 'los arrendamientos' : 'las disposiciones'))]):_c('span',[_vm._v(_vm._s(_vm.arrendamiento ? 'el arrendamiento': 'la disposición'))]),_vm._v(", al realizar esta acción cambiara el estatus de "),(Array.isArray(_vm.disposicion_id))?_c('span',[_vm._v(_vm._s(_vm.arrendamiento ? 'los arrendamientos' : 'las disposiciones'))]):_c('span',[_vm._v(_vm._s(_vm.arrendamiento ? 'el arrendamiento': 'la disposición')+" #"+_vm._s(_vm.disposicion_id)+" ")]),_vm._v(" de "),_c('span',{staticClass:"font-bold"},[_vm._v("\"Prospecto\"")]),_vm._v(" a "),_c('span',{staticClass:"font-bold"},[_vm._v("\"En Espera\"")]),_c('p',{staticClass:"italic"},[_vm._v("Es necesario llenar los siguientes campos")])]),_c('ValidSelect',{attrs:{"rules":"required","label":"Destino de los recursos","placeholder":"Selecciona uno...","fieldClass":"has-text-grey-darker","data":_vm.getDestinoRecursos,"show":"nombre","expanded":true,"realValue":"id"},model:{value:(_vm.destino_recursos),callback:function ($$v) {_vm.destino_recursos=$$v},expression:"destino_recursos"}}),_c('valid-auto-complete',{attrs:{"rules":"required","label":"Acreditado","placeholder":"Buscar...","fieldClass":"mt-4","data":_vm.getClientesLineasCreditoProducto,"show":"alias","expanded":true,"disabled":_vm.is_acreditado},on:{"select":_vm.loadLineas},scopedSlots:_vm._u([{key:"custom",fn:function(slotProps){return [_c('div',{staticClass:"flex items-center"},[_c('b-icon',{staticClass:"has-text-primary",class:{
            'has-text-grey-lighter': slotProps.option.option.checked === null
          },attrs:{"icon":slotProps.option.option.checked === null
              ? 'circle'
              : 'check-circle'}}),_c('span',{staticClass:"has-text-grey-darker-always ml-2"},[_vm._v(_vm._s(slotProps.option.option.alias))])],1)]}}],null,true),model:{value:(_vm.acreditado),callback:function ($$v) {_vm.acreditado=$$v},expression:"acreditado"}}),(_vm.getLineasCliente)?_c('valid-auto-complete',{attrs:{"rules":"required","label":"Línea de crédito","placeholder":"Buscar...","data":_vm.getLineasCliente,"fieldClass":"mt-4","show":"id","expanded":true,"custom":true,"disabled":_vm.is_linea},scopedSlots:_vm._u([{key:"custom",fn:function(slotProps){return [_c('div',[_c('p',{class:{
            'has-text-primary-darker': slotProps.option.option.pasiva == 0,
            'has-text-pasivo': slotProps.option.option.pasiva == 1
          }},[_c('b',[_vm._v("Línea #"+_vm._s(slotProps.option.option.id))]),_vm._v(" - "+_vm._s(slotProps.option.option.producto_financiero.alias)+" ")]),_c('p',[_vm._v(" Capital autorizado: "+_vm._s(_vm._f("hp")(_vm._f("currency")(slotProps.option.option.capital_autorizado)))+" ")]),_c('p',[_vm._v(" Capital disponible: "),_c('span',{staticClass:"font-bold",class:{
              'has-text-danger':
                slotProps.option.option.capital_disponible == 0
            }},[_vm._v(_vm._s(_vm._f("hp")(_vm._f("currency")(slotProps.option.option.capital_disponible))))])])])]}}],null,true),model:{value:(_vm.linea_id),callback:function ($$v) {_vm.linea_id=$$v},expression:"linea_id"}}):_vm._e(),_c('valid-select',{attrs:{"rules":"required","label":"Estado","placeholder":"Selecciona uno...","fieldClass":"has-text-grey-darker","data":_vm.getEstados,"show":"nombre","expanded":true,"realValue":"id"},on:{"input":_vm.loadMunicipios},model:{value:(_vm.estado),callback:function ($$v) {_vm.estado=$$v},expression:"estado"}}),(_vm.getMunicipios)?_c('valid-auto-complete',{attrs:{"rules":"required","label":"Municipio","placeholder":"Selecciona uno...","fieldClass":"has-text-grey-darker","data":_vm.getMunicipios,"show":"nombre","expanded":true,"realValue":"id"},on:{"select":_vm.loadLocalidades},model:{value:(_vm.municipio),callback:function ($$v) {_vm.municipio=$$v},expression:"municipio"}}):_vm._e(),(_vm.getLocalidades)?_c('valid-auto-complete',{attrs:{"rules":"required","label":"Localidad","placeholder":"Selecciona uno...","fieldClass":"has-text-grey-darker","data":_vm.getLocalidades,"show":"nombre","expanded":true,"realValue":"id"},on:{"select":_vm.setLocalidad},model:{value:(_vm.localidad_id),callback:function ($$v) {_vm.localidad_id=$$v},expression:"localidad_id"}}):_vm._e(),_c('div',{staticClass:"columns mt-10"},[_c('div',{staticClass:"column is-3"},[_c('b-button',{staticClass:"has-text-danger font-bold",attrs:{"type":"is-white","expanded":""},on:{"click":function($event){return _vm.$emit('cancelForm')}}},[_vm._v("Cancelar")])],1),_c('div',{staticClass:"column"},[_c('b-button',{staticClass:"font-bold",attrs:{"type":"is-primary","expanded":""},on:{"click":function($event){validate().then(function (result) { return _vm.changeStatus(result); })}}},[_vm._v("Ligar línea de crédito")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }