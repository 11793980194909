<template>
  <div class="text-left">
    <p class="text-3xl font-medium text-left has-text-grey-darker">
      {{
        Array.isArray(disposicion_id)
          ? "Autorizar las disposiciones"
          : "Autorizar la disposición"
      }}
    </p>
    <div class="mb-3 has-text-grey-darker">
      Estas a punto de autorizar
      {{
        Array.isArray(disposicion_id) ? "las disposiciones" : "la disposición"
      }}, al realizar esta acción cambiara el estatus de
      {{
        Array.isArray(disposicion_id)
          ? "las disposiciones"
          : "la disposición " + disposicion_id
      }}
      de <span class="font-bold">"En Espera"</span> a
      <span class="font-bold">"Autorizado"</span>
    </div>
    <div class="columns mt-10">
      <div class="column is-3">
        <b-button
          type="is-white"
          class="has-text-danger font-bold"
          expanded
          @click="$emit('cancelForm')"
        >
          Cancelar
        </b-button>
      </div>
      <div class="column">
        <b-button
          type="is-primary"
          expanded
          class="font-bold"
          @click="changeStatus()"
        >
          {{
            Array.isArray(disposicion_id)
              ? "Autorizar disposiciones"
              : "Autorizar disposición"
          }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnEsperaAutorizado",
  props: {
    disposicion_id: [Number, Array]
  },
  data() {
    return {};
  },
  methods: {
    /*
      Cambia el estatus de la disposición al indicado,
      es posible hacerlo masivamente si el prop disposicion_id es un Array
    */
    changeStatus() {
      if (Array.isArray(this.disposicion_id)) {
        let ids = this.disposicion_id.map(disp => disp.id);
        const data = {
          id: ids
        };
        this.$store.dispatch("disposicion/changeEsperaAutorizadoMasivo", data);
      } else {
        const data = {
          id: this.disposicion_id
        };
        this.$store.dispatch("disposicion/changeEsperaAutorizado", data);
      }
    }
  }
};
</script>
