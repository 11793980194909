<!-- 
Componente que se encarga de mostrar la tarjeta de cambio de status dependiendo del estatus al que se va a actualizar
-->
<template>
  <div
    class="has-background-primary-02 has-border-radius shadow-none p-5 columns"
    :class="{
      'has-background-pasivo-02': pasivo == true && !getSuccess,
      'card-change-status': Array.isArray(disposicion_id) == false,
      'has-background-success-light': getSuccess
    }"
  >
    <div class="column text-center" v-if="getSuccess">
      <success
        @cerrar-cambio-status="cerrarCambioStatus"
        :disposiciones="disposicion_id"
        :status="status"
        :pasivo="pasivo"
      />
    </div>
    <div
      class="column is-4"
      v-if="Array.isArray(disposicion_id) && !getSuccess"
    >
      <list-disposicion-cards :disposiciones="disposicion_id" :total="total" />
    </div>
    <div class="column" id="status_container" v-if="!getSuccess">
      <prospecto-en-espera
        v-if="status == 'Prospecto'"
        :disposicion_id="disposicion_id"
        :arrendamiento="arrendamiento"
        @cancelForm="cancelForm"
      />
      <en-espera-autorizado
        v-if="status == 'En espera'"
        :disposicion_id="disposicion_id"
        @cancelForm="cancelForm"
      />
      <autorizado-entregado
        v-if="status == 'Autorizado'"
        :disposicion_id="disposicion_id"
        @cancelForm="cancelForm"
        :pasivo="pasivo"
        :total="total"
      />
      <archivado-declinado
        v-if="status == 'Archivado'"
        :disposicion_id="disposicion_id"
        @cancelForm="cancelForm"
        :pasivo="pasivo"
      />
      <declinado-en-espera
        v-if="status == 'Declinado'"
        :disposicion_id="disposicion_id"
        @cancelForm="cancelForm"
        :pasivo="pasivo"
      />
    </div>
  </div>
</template>

<script>
import ProspectoEnEspera from "@/components/Disposicion/Status/ProspectoEnEspera";
import EnEsperaAutorizado from "@/components/Disposicion/Status/EnEsperaAutorizado";
import AutorizadoEntregado from "@/components/Disposicion/Status/AutorizadoEntregado";
import ArchivadoDeclinado from "@/components/Disposicion/Status/ArchivadoDeclinado";
import DeclinadoEnEspera from "@/components/Disposicion/Status/DeclinadoEnEspera";
import ListDisposicionCards from "@/components/Disposicion/Status/ListDisposicionCards";
import Success from "@/components/Disposicion/Status/Success";
import { mapGetters } from "vuex";

export default {
  name: "ManejadorStatus",
  props: {
    status: String,
    pasivo: Boolean,
    disposicion_id: [Array, Number],
    tipo_disposicion: String,
    arrendamiento: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProspectoEnEspera,
    EnEsperaAutorizado,
    AutorizadoEntregado,
    ArchivadoDeclinado,
    DeclinadoEnEspera,
    ListDisposicionCards,
    Success
  },
  methods: {
    //Cancela el cambio de estatus
    cancelForm() {
      if (Array.isArray(this.disposicion_id)) {
        this.$store.dispatch("disposicion/changeProcesarOperaciones");
      } else {
        let form = document.getElementById("formstatus");
        form.classList.remove("new-item");
        form.classList.add("remove-item");
        form.onanimationend = () => {
          this.$store.dispatch("disposicion/changeStatus", false);
        };
      }
    },
    //Cierra el cambio de status cuando ya ha sido exitoso
    cerrarCambioStatus() {
      if (Array.isArray(this.disposicion_id)) {
        this.$store.dispatch("disposicion/changeProcesarOperaciones", false);
        this.$store.dispatch("disposicion/getDisposiciones", {
          tipo: this.tipo_disposicion,
          filtro: this.getFiltros
        });
      } else {
        let form = document.getElementById("formstatus");
        form.classList.remove("new-item");
        form.classList.add("remove-item");
        form.onanimationend = () => {
          this.$store.dispatch("disposicion/changeStatus", false);
        };
      }
    }
  },
  computed: {
    ...mapGetters("disposicion", ["getSuccess", "getFiltros"]),
    total() {
      let total = 0;
      if (Array.isArray(this.disposicion_id)) {
        this.disposicion_id.forEach(disp => (total += disp.capital));
      } else {
        total = this.disposicion_id.capital;
      }
      return total;
    }
  }
};
</script>

<style lang="scss">
.card-change-status {
  position: sticky;
  top: 5rem;
}
.max-h-22rem {
  max-height: 22rem;
}
</style>
