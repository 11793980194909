<template>
  <div class="text-left">
    <p class="text-3xl font-medium text-left has-text-grey-darker">
      Recuperar la disposición
    </p>
    <div class="mb-3 has-text-grey-darker">
      Estas a punto de recuperar la disposición archivada, al realizar esta
      acción cambiara el estatus de la disposición #{{ disposicion_id }} de
      <span class="font-bold">"Archivado"</span> a
      <span class="font-bold">"Declinado"</span>
    </div>
    <div class="columns mt-10">
      <div class="column is-3">
        <b-button
          type="is-white"
          class="has-text-danger font-bold"
          expanded
          @click="$emit('cancelForm')"
        >
          Cancelar
        </b-button>
      </div>
      <div class="column">
        <b-button
          type="is-primary"
          expanded
          class="font-bold"
          @click="changeStatus()"
        >
          Recuperar disposición
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArchivadoDeclinado",
  props: {
    disposicion_id: Number
  },
  methods: {
    //Cambia el estatus de la disposición al indicado
    changeStatus() {
      this.$store
        .dispatch("disposicion/declinar", { id: this.disposicion_id })
        .then(data => {
          if (data !== false) {
            this.$store.dispatch("disposicion/changeStatus", false);
          }
        });
    }
  }
};
</script>
