<template>
  <div>
    <img
      src="/images/success-status.png"
      class="m-auto transform scale-90"
      alt="success"
    />
    <p class="font-bold text-3xl mt-2">{{ textSuccess }}</p>
    <p>{{ subtextSuccess }}</p>
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <b-button
          type="is-success"
          class="mt-2"
          expanded
          @click="$emit('cerrar-cambio-status')"
          >Cerrar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Success",
  props: {
    disposiciones: [Number, Array],
    status: String,
    pasivo: Boolean
  },
  computed: {
    /*
      titulo del texto de exito en cambio de status
    */
    textSuccess() {
      if (Array.isArray(this.disposiciones)) {
        switch (this.status) {
          case "Prospecto":
            return "Ligado de línea de crédito exitoso";
          case "En espera":
            return "¡Disposiciones autorizadas!";
          case "Autorizado":
            return "¡Disposiciones entregadas!";
        }
      } else {
        switch (this.status) {
          case "En espera":
            return "Ligado de línea de crédito exitoso.";
          case "Autorizado":
            return "¡Disposición Autorizada!";
          case "Entregado":
            return "¡Disposición Entregada!";
          case "Archivado":
            return "La disposición ha sido archivada";
          case "Declinado":
            return "La disposición ha sido declinada.";
          case "Vigente":
            if(this.pasivo) 
                return  "La disposición ha sido recibida";
              else
                return "La disposición ha sido entregada";
        }
      }
      return "";
    },
    /*
      subtitulo del texto de exito en cambio de status
    */
    subtextSuccess() {
      if (Array.isArray(this.disposiciones)) {
        switch (this.status) {
          case "Prospecto":
            return "Ahora las disposiciones están en estatus “En espera”.";
          case "En espera":
            return "Ahora las disposiciones están en estatus “Autorizado”.";
          case "Autorizado":
            return "Ahora las disposiciones están en estatus “Entregado”.";
          case "Archivado":
            return "Ahora las disposiciones están en estatus “Declinado”.";
          case "Declinado":
            return "Ahora las disposiciones están en estatus “En espera”.";
          case "Vigente":
            return "Ahora las disposiciónes se encuentran en estatus “Vigente”";
        }
      }
      switch (this.status) {
        case "En espera":
          return "Ahora la disposición está en estatus “En espera”.";
        case "Autorizado":
          return "Ahora la disposición está en estatus “Autorizado”.";
        case "Entregado":
          return "Ahora la disposición está en estatus “Entregado”.";
        case "Archivado":
          return "Ahora la disposición está en estatus “Archivado”.";
        case "Declinado":
          return "Ahora la disposición está en estatus “Declinado”.";
        case "Vigente":
          return "Ahora la disposición está en estatus “Vigente”.";
      }
      return "¡La disposición ha cambiado de estatus!";
    }
  }
};
</script>
