<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <p class="text-3xl font-medium text-left has-text-grey-darker">
      Resolver disposición
    </p>
    <div class="mb-3 has-text-grey-darker text-left">
      Estas a punto de resolver una disposición declinada, al realizar esta
      acción cambiara el estatus de la disposición #{{ disposicion_id }} de
      <span class="font-bold">"Declinado"</span> a
      <span class="font-bold">"En Espera"</span>
      <p class="italic">
        Es necesario comprobar los siguientes campos
      </p>
    </div>
    <ValidSelect
      rules="required"
      label="Destino de los recursos"
      placeholder="Selecciona uno..."
      v-model="destino_recursos"
      fieldClass="has-text-grey-darker"
      :data="getDestinoRecursos"
      show="nombre"
      :expanded="true"
      realValue="id"
    />
    <ValidAutoComplete
      rules="required"
      label="Acreditado"
      placeholder="Buscar..."
      v-model="acreditado"
      fieldClass="mt-4"
      :data="getClientesConLineasCredito"
      show="alias"
      :expanded="true"
      @select="loadLineas"
    >
      <template v-slot:custom="slotProps">
        <div class="flex items-center">
          <b-icon
            class="has-text-primary"
            :class="{
              'has-text-grey-lighter': slotProps.option.option.checked === null
            }"
            :icon="
              slotProps.option.option.checked === null
                ? 'circle'
                : 'check-circle'
            "
          />
          <span class="has-text-grey-darker-always ml-2">
            {{ slotProps.option.option.alias }}
          </span>
        </div>
      </template>
    </ValidAutoComplete>
    <ValidAutoComplete
      rules="required"
      label="Línea de crédito"
      placeholder="Buscar..."
      v-model="linea_id"
      :data="getLineasCliente"
      fieldClass="mt-4"
      show="id"
      :expanded="true"
      v-if="getLineasCliente"
      :custom="true"
    >
      <template v-slot:custom="slotProps">
        <div>
          <p
            :class="{
              'has-text-primary-darker': slotProps.option.option.pasiva == 0,
              'has-text-pasivo': slotProps.option.option.pasiva == 1
            }"
          >
            <b>Línea #{{ slotProps.option.option.id }}</b> -
            {{ slotProps.option.option.producto_financiero.alias }}
          </p>
          <p>
            Capital autorizado:
            {{ slotProps.option.option.capital_autorizado | currency | hp }}
          </p>
          <p>
            Capital disponible:
            <span
              :class="{
                'has-text-danger':
                  slotProps.option.option.capital_disponible == 0
              }"
              class="font-bold"
              >{{ slotProps.option.option.capital_disponible | currency | hp }}
            </span>
          </p>
        </div>
      </template>
    </ValidAutoComplete>
    <valid-select
      rules="required"
      label="Estado"
      placeholder="Selecciona uno..."
      v-model="estado"
      fieldClass="has-text-grey-darker"
      :data="getEstados"
      show="nombre"
      :expanded="true"
      realValue="id"
      @input="loadMunicipios"
    />
    <valid-auto-complete
      rules="required"
      label="Municipio"
      v-if="getMunicipios"
      placeholder="Selecciona uno..."
      v-model="municipio"
      fieldClass="has-text-grey-darker"
      :data="getMunicipios"
      show="nombre"
      :expanded="true"
      realValue="id"
      @select="loadLocalidades"
    />
    <valid-auto-complete
      rules="required"
      label="Localidad"
      v-if="getLocalidades"
      placeholder="Selecciona uno..."
      v-model="localidad_id"
      fieldClass="has-text-grey-darker"
      :data="getLocalidades"
      show="nombre"
      :expanded="true"
      realValue="id"
      @select="setLocalidad"
    />
    <div class="columns mt-10">
      <div class="column is-3">
        <b-button
          type="is-white"
          class="has-text-danger font-bold"
          expanded
          @click="$emit('cancelForm')"
        >
          Cancelar
        </b-button>
      </div>
      <div class="column">
        <b-button
          type="is-primary"
          expanded
          class="font-bold"
          @click="validate().then(result => changeStatus(result))"
        >
          Resolver disposición
        </b-button>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import ValidSelect from "@/components/form/ValidSelect";
import { mapGetters } from "vuex";

export default {
  name: "DeclinadoEnEspera",
  components: {
    ValidationObserver,
    ValidAutoComplete,
    ValidSelect
  },
  props: {
    disposicion_id: Number
  },
  data() {
    return {
      acreditado: "",
      linea_id: "",
      disable_linea: true,
      acreditado_id: null,
      destino_recursos: null,
      is_linea: false,
      is_acreditado: false,
      estado: null,
      municipio: null,
      localidad_id: null,
      localidad_inegi: null
    };
  },
  methods: {
    /*
      Cambia el estatus de la disposición al indicado
      @result (Boolean): resultado de las validaciones de inputs
    */
    changeStatus(result) {
      if (!result) {
        return;
      }
      const data = {
        acreditado_id: this.acreditado_id,
        linea_credito_id: this.linea_id,
        id: this.disposicion_id,
        destino_recursos_id: this.destino_recursos,
        localidad_id: this.localidad_inegi
      };
      this.$store
        .dispatch("disposicion/changeProspectoEspera", data)
        .then(data => {
          if (data !== false) {
            this.$store.dispatch("disposicion/changeStatus", false);
          }
        });
    },
    //Carga las lineas de credito del acreditado seleccionado
    loadLineas(acreditado) {
      this.acreditado_id = acreditado.id;
      this.$store
        .dispatch("lineascredito/getLineasByPersona", {
          persona_id: acreditado.id,
          producto_financiero_id: this.getDisposicionDetalle.producto_financiero
            .id
        })
        .then(res => {
          if (!res || res.length == 0) {
            this.notification(
              "warning",
              "Cliente sin línea de crédito",
              "El cliente seleccionado no tiene ninguna línea de crédito activa"
            );
          } else {
            this.disable_linea = false;
          }
        });
    },
    loadMunicipios() {
      this.$store.dispatch("catalogos/getMunicipios", this.estado);
    },
    loadLocalidades(val) {
      this.$store.dispatch("catalogos/getLocalidades", val.id);
    },
    setLocalidad(val) {
      this.localidad_inegi = val.id;
    }
  },
  computed: {
    ...mapGetters("catalogos", [
      "getClientesConLineasCredito",
      "getDestinoRecursos",
      "getEstados",
      "getMunicipios",
      "getLocalidades"
    ]),
    ...mapGetters("lineascredito", ["getLineasCliente"]),
    ...mapGetters("disposicion", ["getDisposicionDetalle"])
  },
  mounted() {
    if (this.getDisposicionDetalle.localidad) {
      this.$store.dispatch("catalogos/getMunicipios", this.estado_id);
      this.$store.dispatch("catalogos/getLocalidades", this.municipio_id);
      this.estado = this.getDisposicionDetalle.localidad.estado_id;
      this.municipio = this.getDisposicionDetalle.localidad.municipio.nombre;
      this.localidad_inegi = this.getDisposicionDetalle.localidad.id;
      this.localidad_id = this.getDisposicionDetalle.localidad.nombre;
    }
    if (this.getDisposicionDetalle.linea_credito.length > 0) {
      this.linea_id = this.getDisposicionDetalle.linea_credito[0].id;
      this.is_linea = true;
    }
    if (this.getDisposicionDetalle.acreditado) {
      this.acreditado = this.getDisposicionDetalle.acreditado.nombre_completo.split(
        "|"
      )[0];
      this.loadLineas({
        id: this.getDisposicionDetalle.acreditado.id
      });
      this.is_acreditado = true;
    }
    if (this.getClientesConLineasCredito == null) {
      this.$store.dispatch("catalogos/getClientesConLineasCredito");
    }
    if (this.getDestinoRecursos == null) {
      this.$store.dispatch("catalogos/getDestinoRecursos");
    }
    if (this.getDisposicionDetalle.destino_recursos_id) {
      this.destino_recursos = this.getDisposicionDetalle.destino_recursos_id;
    }
    if (this.getEstados == null) {
      this.$store.dispatch("catalogos/getEstados");
    }
  }
};
</script>
